import * as React from "react";
import {
  graphql,
  loadQuery,
  PreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { useAuth } from "../core";
import { User } from "../core/Auth";
import { SignInOptions } from "../core/AuthProvider";
import { relay } from "../core/relay";
import AppContextProviderQuery, {
  type AppContextProviderQuery as AppContextProviderQueryType,
} from "../queries/AppContextProviderQuery.graphql";

graphql`
  query AppContextProviderQuery {
    me {
      ...Auth_user
    }
    ...CatalogCategoriesFragment
    cart {
      ...CartFragment
    }
    ...TopDiscountHintFragment
    orders(filter: { states: ["received", "picking", "shipping"] }) {
      totalCount
    }
  }
`;

const initialQueryRef = loadQuery<AppContextProviderQueryType>(
  relay,
  AppContextProviderQuery,
  {},
);

const defaultAppContextData = {
  queryRef: initialQueryRef as PreloadedQuery<
    AppContextProviderQueryType,
    Record<string, unknown>
  >,
  refresh: () => {},
  signIn: (() => {}) as (_options?: SignInOptions) => Promise<User | null>,
};

export type AppContextDataType = typeof defaultAppContextData;

export const AppContext = React.createContext<AppContextDataType>(
  defaultAppContextData,
);

export const AppContextProvider = (props: React.PropsWithChildren) => {
  const auth = useAuth();

  const [queryRef, loadQuery] = useQueryLoader(
    AppContextProviderQuery,
    initialQueryRef,
  );

  const appContextData = React.useMemo(
    () => ({
      queryRef: queryRef ?? initialQueryRef,
      refresh: () => loadQuery({}, { fetchPolicy: "network-only" }),
      signIn: async (options?: SignInOptions): Promise<User | null> => {
        const res = await auth.signIn(options);
        loadQuery({}, { fetchPolicy: "network-only" });
        return res;
      },
    }),
    [queryRef, loadQuery],
  );

  return (
    <AppContext.Provider value={appContextData}>
      {props.children}
    </AppContext.Provider>
  );
};

export function useAppContext(): AppContextDataType {
  return React.useContext(AppContext);
}

/// TODO: вместо этого нужно сделать так, чтобы AppContextProvider цеплялся к
/// оповещениям об авторизации от AuthProvider. Тогда можно будет
/// использовать AuthProvider.useAuth вместо этого костыля и данные всё равно
/// будут обновляться. Такой подход как сейчас заставляет нас использовать
/// useAppContext вместо useAuth, в админке, что приводит к лишним обращениям к БД.
export function useHandleSignIn(): (event: React.MouseEvent) => void {
  const appContext = useAppContext();
  function handleSignIn(event: React.MouseEvent): void {
    event.preventDefault();
    appContext.signIn().catch(() => undefined);
  }
  return handleSignIn;
}
