import { AccountTree, Discount, Settings } from "@mui/icons-material";
import {
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import * as React from "react";
import { useFragment, usePreloadedQuery } from "react-relay";
import { useAppContext } from "../components/AppContextProvider";
import { useAuth, useNavigate } from "../core";
import { Logout } from "../icons";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";
import TopDiscountHintFragment, {
  TopDiscountHintFragment$key,
} from "../queries/TopDiscountHintFragment.graphql";
import UserMAInfoFragment, {
  UserMAInfoFragment$key,
} from "../queries/UserMAInfoFragment.graphql";

type UserMenuProps = Omit<
  MenuProps,
  | "id"
  | "role"
  | "open"
  | "getContentAnchorEl"
  | "anchorOrigin"
  | "transformOrigin"
>;

function UserMenu(props: UserMenuProps): JSX.Element {
  const { PaperProps, MenuListProps, ...other } = props;

  const navigate = useNavigate();
  const auth = useAuth();
  const appContext = useAppContext();
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef,
  );

  const data = useFragment<TopDiscountHintFragment$key>(
    TopDiscountHintFragment,
    appData,
  );
  const userMAInfo = useFragment<UserMAInfoFragment$key>(
    UserMAInfoFragment,
    data.me?.maInfo ?? null,
  );

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    props.onClose?.(event, "backdropClick");
    navigate(event);
  }

  const hasIntellektualnaya =
    !!userMAInfo && userMAInfo.isInProgram && !!userMAInfo.referralCode;

  return (
    <Menu
      id="user-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ ...PaperProps, sx: { ...PaperProps?.sx, width: 320 } }}
      MenuListProps={{ ...MenuListProps, dense: true }}
      {...other}
    >
      <MenuItem component={Link} href="/discounts" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Discount />
        </ListItemIcon>
        <ListItemText primary="Ваши скидки" />
      </MenuItem>
      {hasIntellektualnaya && (
        <MenuItem
          component={Link}
          href="/intellektualnaya"
          onClick={handleClick}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Discount />
          </ListItemIcon>
          <ListItemText primary="Получить интеллектуальную ссылку" />
        </MenuItem>
      )}
      <MenuItem component={Link} href="/referers" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <AccountTree />
        </ListItemIcon>
        <ListItemText primary="Ваши рефералы" />
      </MenuItem>
      <MenuItem component={Link} href="/settings" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Данные и настройки" />
      </MenuItem>
      <MenuItem onClick={auth.signOut}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Выйти" />
      </MenuItem>
    </Menu>
  );
}

export { UserMenu, type UserMenuProps };
