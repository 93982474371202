import { ShoppingCartOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useFragment, usePreloadedQuery } from "react-relay";
import { useConfig, useLocation, useNavigate } from "../core";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";
import CartFragment, {
  CartFragment$key,
} from "../queries/CartFragment.graphql";
import { useAppContext } from "./AppContextProvider";
import MyBadge from "./MyBadge";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

export default function CartButton(): JSX.Element {
  const navigate = useNavigate();
  const config = useConfig();
  const theme = useTheme();
  const urlLocation = useLocation();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const appContext = useAppContext();
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef,
  );
  const cart = useFragment<CartFragment$key>(CartFragment, appData.cart);
  const cartProductsCount = cart?.items?.length ?? 0;
  const cartSx = smDown
    ? {
        borderRadius: "50%",
        backgroundColor: "black",
        opacity: 0.8,
        minWidth: "2em",
        minHeight: "2em",
        boxShadow: "2px 2px 4px rgba(0,0,0,0.5)",
        "&:hover": {
          backgroundColor: "black",
        },
      }
    : {};
  const cartBadgeSx = smDown
    ? { position: "fixed", right: "1.8em", top: "5.8em", zIndex: 1000 }
    : {};
  const cartButtonVisible =
    !smDown ||
    (urlLocation.pathname !== config.app.cartPath && cartProductsCount > 0);

  if (!cartButtonVisible) return <></>;

  return (
    <MyBadge sx={cartBadgeSx} badgeContent={cartProductsCount} color="primary">
      <Tooltip title="Откройте список заказываемых товаров" enterDelay={800}>
        <IconButton
          sx={cartSx}
          disabled={cartProductsCount == 0}
          href="/cart"
          onClick={navigate}
          color="inherit"
        >
          <ShoppingCartOutlined />
        </IconButton>
      </Tooltip>
    </MyBadge>
  );
}
