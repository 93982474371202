/**
 * @generated SignedSource<<d2b0b084911c41020ab9a041e0e84ef9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Auth_user$data = {
  readonly id: string;
  readonly username: string;
  readonly email: string | null;
  readonly phone: string | null;
  readonly name: string | null;
  readonly referralCnt: number | null;
  readonly attractedPurchasesAmount: number | null;
  readonly " $fragmentType": "Auth_user";
};
export type Auth_user$key = {
  readonly " $data"?: Auth_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Auth_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Auth_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralCnt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attractedPurchasesAmount",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "09d3a2770ec4bd712b92ae9506bdc9a0";

export default node;
