import { CssBaseline, PaletteMode } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Action, Update } from "history";
import * as React from "react";
import { CookiesProvider } from "react-cookie";
import { Environment, RelayEnvironmentProvider } from "react-relay";
import type { Config } from "../config";
import { AuthProvider, ConfigContext } from "../core";
import { History, HistoryContext, LocationContext } from "../core/history";
import type { Route, RouteResponse } from "../core/router";
import { resolveRoute } from "../core/router";
import { ThemeProvider } from "../theme";
import { ErrorPage } from "./ErrorPage";

type AppProps = {
  config: Config;
  history: History;
  relay: Environment;
  routes: readonly Route<any, any>[];
};

export class AppBase extends React.Component<AppProps> {
  state = {
    route: undefined as RouteResponse | undefined,
    location: this.props.history.location,
    error: undefined as Error | undefined,
    theme: (window?.localStorage?.getItem("theme") === "dark"
      ? "dark"
      : "light") as PaletteMode,
  };

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  dispose?: () => void;

  loadPageData(): void {
    this.renderPath({
      location: this.props.history.location,
      action: Action.Pop,
    });
  }

  componentDidMount(): void {
    this.dispose = this.props.history.listen(this.renderPath);
    this.loadPageData();
  }

  componentDidUpdate(): void {
    if (this.state.route?.title) {
      self.document.title = this.state.route.title + " - MadNuts.ru";
    }
    const hash = this.state.location.hash;
    if (hash) {
      const anchor = document.getElementById(hash.substring(1));
      if (anchor) {
        anchor.scrollIntoView();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose();
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  renderPath = async (ctx: Update): Promise<void> => {
    window.scrollTo(0, 0);
    resolveRoute(
      {
        path: ctx.location.pathname,
        query: new URLSearchParams(ctx.location.search),
        relay: this.props.relay,
      },
      this.props.routes,
    ).then((route) => {
      if (route.error) console.error(route.error);
      this.setState({ route, location: ctx.location, error: route.error });
    });
  };

  renderContent(): JSX.Element {
    return <></>;
  }

  render(): JSX.Element {
    const { config, history } = this.props;
    const { location, error } = this.state;

    if (error) {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <ErrorPage error={error} history={history} />;
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }

    return (
      <CookiesProvider>
        <ConfigContext.Provider value={config}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider>
                <RelayEnvironmentProvider environment={this.props.relay}>
                  <AuthProvider>
                    <HistoryContext.Provider value={history}>
                      <LocationContext.Provider value={location}>
                        <CssBaseline />
                        {this.renderContent()}
                      </LocationContext.Provider>
                    </HistoryContext.Provider>
                  </AuthProvider>
                </RelayEnvironmentProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ConfigContext.Provider>
      </CookiesProvider>
    );
  }
}
