import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { useConfig } from "../core";
import { useUrlSetPath } from "../core/history";
import CatalogBreadcrumbsFragment, {
  CatalogBreadcrumbsFragment$key,
} from "../queries/CatalogBreadcrumbsFragment.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

graphql`
  fragment CatalogBreadcrumbsFragment on Query {
    categoryPath(navName: $breadcrumbHeadNavName, type: $breadcrumbHeadType) {
      id
      name
      navName
      level
    }
  }
`;

export interface CatalogBreadcrumbsProps {
  fragmentRef: CatalogBreadcrumbsFragment$key;
}

export const CatalogBreadcrumbs = (props: CatalogBreadcrumbsProps) => {
  const data = useFragment(CatalogBreadcrumbsFragment, props.fragmentRef);
  const config = useConfig();
  const setCategory = useUrlSetPath("/catalog");
  const navigate = React.useCallback(
    (
      event:
        | React.MouseEvent<HTMLAnchorElement>
        | React.MouseEvent<HTMLSpanElement>,
      navName: string | null,
    ) => {
      event.preventDefault();
      setCategory(navName);
    },
    [setCategory],
  );

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ my: 2 }}>
      {[
        <Link
          key={0}
          underline="hover"
          color="inherit"
          fontWeight={500}
          href={config.app.catalogRoot}
          onClick={(e) => navigate(e, null)}
        >
          Все товары
        </Link>,
      ].concat(
        data.categoryPath
          ? data.categoryPath?.map((element) => (
              <Link
                key={element?.level}
                underline="hover"
                color="inherit"
                fontWeight={500}
                href="/"
                onClick={(e) => navigate(e, element?.navName ?? null)}
              >
                {element?.name}
              </Link>
            ))
          : [],
      )}
    </Breadcrumbs>
  );
};
