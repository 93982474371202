import {
  Checkbox,
  FormControlLabel,
  Link,
  SxProps,
  Theme,
} from "@mui/material";
import * as React from "react";

export interface ConfidentialityConsentProps {
  agreementChecked: boolean;
  setAgreementChecked: (value: boolean) => void;
  sx?: SxProps<Theme>;
}

export function ConfidentialityConsent(
  props: ConfidentialityConsentProps,
): JSX.Element {
  const { agreementChecked, setAgreementChecked, sx } = props;
  return (
    <FormControlLabel
      sx={{ ...sx }}
      control={
        <Checkbox
          onChange={() => setAgreementChecked(!agreementChecked)}
          checked={agreementChecked}
        />
      }
      label={
        <Link tabIndex={-1} target="_blank" href="/personal-data-policy">
          С политикой конфиденциальности при хранении и обработке персональных
          данных в соответствии с ФЗ 152 согласен
        </Link>
      }
    />
  );
}
