import { Container } from "@mui/material";
import React from "react";
import { graphql } from "relay-runtime";
import { CatalogBreadcrumbs } from "../components/CatalogBreadcrumbs";
import { MyDelaemTsenyDostupnymi } from "../components/MyDelaemTsenyDostupnymi";
import { ProductList } from "../components/ProductList";
import { TopDiscountHint } from "../components/TopDiscountHint";
import { RouterContext } from "../core/router.types";
import {
  CatalogQuery$data,
  CatalogQuery$variables,
} from "../queries/CatalogQuery.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

const getProductListQueryVars = (
  params: Record<string, string> | undefined,
  query: URLSearchParams | null,
  numProducts = 24,
): CatalogQuery$variables => {
  const categorySlug = params?.categoryNavName ?? "";
  return {
    search: query?.get("search") ?? "",
    categorySlug,
    breadcrumbHeadNavName: categorySlug,
    breadcrumbHeadType: "Category",
    numProducts,
  };
};

export const getQueryVars = (ctx: RouterContext): CatalogQuery$variables =>
  getProductListQueryVars(ctx.params, ctx.query ?? null);

graphql`
  query CatalogQuery(
    $search: String!
    $categorySlug: String
    $productCursor: String
    $numProducts: Int
    $breadcrumbHeadNavName: String
    $breadcrumbHeadType: String
  ) {
    ...CatalogBreadcrumbsFragment
    ...ProductListFragment
    ...TopDiscountHintFragment
  }
`;

export type CatalogProps = {
  data: CatalogQuery$data;
};

function Catalog({ data }: CatalogProps): JSX.Element {
  return (
    <>
      <Container sx={{ mt: 2 }}>
        <TopDiscountHint fragmentRef={data} />
      </Container>

      <Container sx={{ mt: 2 }}>
        <MyDelaemTsenyDostupnymi />
      </Container>

      <Container sx={{ mt: 1 }}>
        <CatalogBreadcrumbs fragmentRef={data} />

        <ProductList infiniteScroll productsFragRef={data} />
      </Container>
    </>
  );
}

export default Catalog;
export type Catalog = typeof Catalog;
