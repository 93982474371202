/**
 * @generated SignedSource<<87615590cfcc2bb6aea57bc386ef1ef7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountSettingsQuery$variables = {};
export type AccountSettingsQuery$data = {
  readonly me: {
    readonly maInfo: {
      readonly isInProgram: boolean | null;
      readonly referralCode: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"Auth_user">;
  } | null;
};
export type AccountSettingsQuery = {
  variables: AccountSettingsQuery$variables;
  response: AccountSettingsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProgram",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Auth_user"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referralCnt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attractedPurchasesAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ee2518868e4dd65d57467775635205e",
    "id": null,
    "metadata": {},
    "name": "AccountSettingsQuery",
    "operationKind": "query",
    "text": "query AccountSettingsQuery {\n  me {\n    ...Auth_user\n    maInfo {\n      isInProgram\n      referralCode\n      id\n    }\n    id\n  }\n}\n\nfragment Auth_user on User {\n  id\n  username\n  email\n  phone\n  name\n  referralCnt\n  attractedPurchasesAmount\n}\n"
  }
};
})();

(node as any).hash = "30a5d8efd818b1387cfb28d8e4e65e78";

export default node;
