import { Box, BoxProps, Button } from "@mui/material";
import React from "react";
import { useAddToCart } from "../common/addToCart";

export type AddToCartControlProps = BoxProps & {
  productId: string;
};

export const AddToCartControl = (props: AddToCartControlProps) => {
  const boxProps: Partial<AddToCartControlProps> = { ...props };
  delete boxProps.productId;

  const addToCartHook = useAddToCart();
  const addToCart = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      addToCartHook(props.productId);
    },
    [props.productId],
  );
  return (
    <Box {...boxProps}>
      <Button variant="contained" color="secondary" onClick={addToCart}>
        В корзину
      </Button>
    </Box>
  );
};
