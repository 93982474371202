import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  IconButton,
  Link,
  Paper,
  Typography,
  Zoom,
} from "@mui/material";
import { ruRURub } from "dinno-util";
import * as React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { AddToCartControl } from "../components/AddToCartControl";
import { CatalogBreadcrumbs } from "../components/CatalogBreadcrumbs";
import { MyDelaemTsenyDostupnymi } from "../components/MyDelaemTsenyDostupnymi";
import { ProductList } from "../components/ProductList";
import { RouterContext } from "../core/router.types";
import nav from "../navigation";
import ProductPageFragment, {
  ProductPageFragment$data,
  ProductPageFragment$key,
} from "../queries/ProductPageFragment.graphql";
import {
  ProductPageQuery$data,
  ProductPageQuery$variables,
} from "../queries/ProductPageQuery.graphql";

const getProductPageQueryVars = (
  params: Record<string, string> | undefined,
): ProductPageQuery$variables => {
  const navName = params?.navName ?? "";
  return {
    navName,
    breadcrumbHeadNavName: navName,
    breadcrumbHeadType: "Product",
  };
};

export const getQueryVars = (
  ctx: RouterContext,
): ProductPageQuery$variables => {
  return getProductPageQueryVars(ctx.params);
};

graphql`
  query ProductPageQuery(
    $navName: String
    $breadcrumbHeadNavName: String
    $breadcrumbHeadType: String
    $search: String = ""
    $categorySlug: String = "populyarnoe"
    $productCursor: String = null
    $numProducts: Int = 8
  ) {
    product(navName: $navName) {
      name
      ...ProductPageFragment
    }
    ...CatalogBreadcrumbsFragment
    me {
      maInfo {
        isInProgram
      }
    }
    ...ProductListFragment
  }
`;

graphql`
  fragment ProductPageFragment on Product {
    id
    name
    description
    image
    images {
      image
    }
    price
    maker
    unit
    unitPrice
    axis1 {
      name
      editionId
      editions {
        id
        name
      }
    }
    axis2 {
      name
      editionId
      editions {
        id
        name
      }
    }
    axis3 {
      name
      editionId
      editions {
        id
        name
      }
    }
    editions {
      id
      navName
      axis1Value {
        id
      }
      axis2Value {
        id
      }
      axis3Value {
        id
      }
    }
  }
`;

function ProductImage({
  image,
  photoZoomedIn,
  setPhotoZoomedIn,
}: {
  image: string | null;
  photoZoomedIn: string | null;
  setPhotoZoomedIn: (image: string | null) => void;
}) {
  return (
    <>
      <Box
        sx={{ cursor: "zoom-in" }}
        width="100%"
        component="img"
        src={image ?? ""}
        onClick={() => setPhotoZoomedIn(image)}
      />
      <Zoom in={photoZoomedIn === image}>
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: "modal",
            objectFit: "contain",
            cursor: "zoom-out",
            backdropFilter: "blur(5px)",
          }}
          width="100%"
          height="100%"
          component="img"
          src={image ?? ""}
          onClick={() => setPhotoZoomedIn(null)}
        />
      </Zoom>
    </>
  );
}

interface ImageGalleryProps {
  images: string[];
  fallbackImage: string;
  alt?: string;
}

const ImageGallery = (props: ImageGalleryProps) => {
  const { images, fallbackImage } = props;
  const [photoZoomedIn, setPhotoZoomedIn] = React.useState<string | null>(null);
  const productImages =
    !!images && images.length != 0 ? images : [fallbackImage];
  const [selectedImage, setSelectedImage] = React.useState<string>(
    productImages[0],
  );
  const multipleImages = productImages.length > 1;
  return (
    <>
      <ProductImage
        key={selectedImage}
        image={selectedImage}
        photoZoomedIn={photoZoomedIn}
        setPhotoZoomedIn={setPhotoZoomedIn}
      />
      {multipleImages &&
        productImages.map((img) => (
          <Box
            key={img}
            component="img"
            src={img}
            alt={props.alt ?? "Изображение товара"}
            onClick={() => setSelectedImage(img)}
            sx={{
              width: { xs: "50px", sm: "100px" },
              height: { xs: "50px", sm: "100px" },
              cursor: "pointer",
              mr: 1,
              mt: 1,
              imageFit: "cover",
            }}
          />
        ))}
    </>
  );
};

export type ProductPageProps = {
  data: ProductPageQuery$data;
};

export default function ProductPage({ data }: ProductPageProps): JSX.Element {
  const product = useFragment<ProductPageFragment$key>(
    ProductPageFragment,
    data.product,
  );
  const axis1 = product?.axis1;
  const axis1EditionsOrder = buildAxisEditionsOrder(axis1);
  const axis2 = product?.axis2;
  const axis2EditionsOrder = buildAxisEditionsOrder(axis2);
  const axis3 = product?.axis3;
  const axis3EditionsOrder = buildAxisEditionsOrder(axis3);

  const axis1Nav: {
    [k: string]: { navName: string | null; distance: number };
  } = {};
  const axis2Nav: {
    [k: string]: { navName: string | null; distance: number };
  } = {};
  const axis3Nav: {
    [k: string]: { navName: string | null; distance: number };
  } = {};
  if (product?.editions) {
    for (const productEdition of product.editions) {
      if (!productEdition?.navName) continue;

      const axis1Edition = productEdition?.axis1Value?.id;
      const axis1CurrentEdition = product.axis1?.editionId;
      const axis2Edition = productEdition?.axis2Value?.id;
      const axis2CurrentEdition = product.axis2?.editionId;
      const axis3Edition = productEdition?.axis3Value?.id;
      const axis3CurrentEdition = product.axis3?.editionId;

      const distance1 = axisDistance(
        axis1Edition,
        axis1CurrentEdition,
        axis1EditionsOrder,
      );
      const distance2 = axisDistance(
        axis2Edition,
        axis2CurrentEdition,
        axis2EditionsOrder,
      );
      const distance3 = axisDistance(
        axis3Edition,
        axis3CurrentEdition,
        axis3EditionsOrder,
      );

      if (axis1Edition) {
        const nav1 = axis1Nav[axis1Edition] ?? {
          navName: null,
          distance: Number.MAX_SAFE_INTEGER,
        };
        if (distance2 + distance3 < nav1.distance) {
          nav1.navName = nav.product(productEdition);
          nav1.distance = distance2 + distance3;
          axis1Nav[axis1Edition] = nav1;
        }
      }

      if (axis2Edition) {
        const nav2 = axis2Nav[axis2Edition] ?? {
          navName: null,
          distance: Number.MAX_SAFE_INTEGER,
        };
        if (distance1 + distance3 < nav2.distance) {
          nav2.navName = nav.product(productEdition);
          nav2.distance = distance1 + distance3;
          axis2Nav[axis2Edition] = nav2;
        }
      }

      if (axis3Edition) {
        const nav3 = axis3Nav[axis3Edition] ?? {
          navName: null,
          distance: Number.MAX_SAFE_INTEGER,
        };
        if (distance1 + distance2 < nav3.distance) {
          nav3.navName = nav.product(productEdition);
          nav3.distance = distance1 + distance2;
          axis3Nav[axis3Edition] = nav3;
        }
      }
    }
  }

  return (
    <>
      <Container sx={{ mt: 2 }}>
        <MyDelaemTsenyDostupnymi />
      </Container>
      {data.me?.maInfo?.isInProgram && (
        <Container sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" href="/discounts">
            Перед формированием заказа проверьте вашу СКИДКУ
          </Button>
        </Container>
      )}
      <Container sx={{ marginTop: 3, marginBottom: 3 }}>
        <CatalogBreadcrumbs fragmentRef={data} />

        {product && (
          <React.Suspense fallback={<CircularProgress />}>
            <Paper
              component="div"
              sx={{
                display: { xs: "block", md: "flex" },
                flexDirection: "row",
                alignItems: "start",
                p: 3,
                mb: 8,
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                <ImageGallery
                  key={product.id}
                  images={product.images?.map((i) => i?.image ?? "") ?? []}
                  fallbackImage={product.image ?? ""}
                />
              </Box>
              <Box
                component="div"
                sx={{
                  ml: { xs: 0, md: 3 },
                  mt: { xs: 2, md: 0 },
                  width: { xs: "100%", md: "60%" },
                }}
              >
                <Typography variant="h1">{product.name}</Typography>
                <Typography sx={{ py: 1, fontSize: 22 }}>
                  {product.maker}
                </Typography>
                {!!product.axis1?.editions &&
                  product.axis1?.editions.length !== 0 && (
                    <Box>
                      {product.axis1.name}:&nbsp;&nbsp;
                      <ButtonGroup>
                        {product.axis1?.editions?.map(
                          (edition) =>
                            !!edition?.id && (
                              <Button
                                component={Link}
                                href={
                                  axis1Nav[edition?.id].navName ?? undefined
                                }
                                variant={
                                  product.axis1?.editionId === edition.id
                                    ? "contained"
                                    : "outlined"
                                }
                                key={edition?.id}
                              >
                                {edition?.name}
                              </Button>
                            ),
                        )}
                      </ButtonGroup>
                    </Box>
                  )}
                {!!product.axis2?.editions &&
                  product.axis2?.editions.length !== 0 && (
                    <Box marginTop={1}>
                      {product.axis2.name}:&nbsp;&nbsp;
                      <ButtonGroup>
                        {product.axis2?.editions?.map(
                          (edition) =>
                            !!edition?.id && (
                              <Button
                                component={Link}
                                href={
                                  axis2Nav[edition?.id].navName ?? undefined
                                }
                                variant={
                                  product.axis2?.editionId === edition.id
                                    ? "contained"
                                    : "outlined"
                                }
                                key={edition?.id}
                              >
                                {edition?.name}
                              </Button>
                            ),
                        )}
                      </ButtonGroup>
                    </Box>
                  )}
                {!!product.axis3?.editions &&
                  product.axis3?.editions.length !== 0 && (
                    <Box marginTop={1}>
                      {product.axis3.name}:&nbsp;&nbsp;
                      <ButtonGroup>
                        {product.axis3?.editions?.map(
                          (edition) =>
                            !!edition?.id && (
                              <Button
                                component={Link}
                                href={
                                  axis3Nav[edition?.id].navName ?? undefined
                                }
                                variant={
                                  product.axis3?.editionId === edition.id
                                    ? "contained"
                                    : "outlined"
                                }
                                key={edition?.id}
                              >
                                {edition?.name}
                              </Button>
                            ),
                        )}
                      </ButtonGroup>
                    </Box>
                  )}
                <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
                  <Box>
                    <Typography
                      sx={{ pt: 1 }}
                      variant="h5"
                      component="div"
                      color="text.primary"
                    >
                      {ruRURub.format(product.price ?? 0)}
                    </Typography>
                    <AddToCartControl sx={{ py: 2 }} productId={product.id} />
                  </Box>
                  {!data.me?.maInfo?.isInProgram && (
                    <IconButton
                      href="/klub-po-karmanu"
                      sx={{ ml: { xs: 0, md: "auto" } }}
                    >
                      <Box
                        sx={{ width: { xs: 130, sm: 150 } }}
                        component="img"
                        alt="Клуб «По карману» - MadNuts.ru"
                        src="https://s3.madnuts.ru/img/club2.png"
                      />
                    </IconButton>
                  )}
                </Box>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: product.description ?? "",
                  }}
                />
              </Box>
            </Paper>
          </React.Suspense>
        )}
        <Typography marginBottom={3} variant="h2">
          Смотрите также:
        </Typography>
        <React.Suspense>
          <ProductList productsFragRef={data} />
        </React.Suspense>
      </Container>
    </>
  );

  function axisDistance(
    axisEdition: string | undefined,
    axisCurrentEdition: string | null | undefined,
    axisEditionsOrder: Map<string, number>,
  ) {
    const axisEditionOrder = axisEdition
      ? (axisEditionsOrder.get(axisEdition) ?? 0)
      : 0;
    const axisCurrentEditionOrder = axisCurrentEdition
      ? (axisEditionsOrder.get(axisCurrentEdition) ?? 0)
      : 0;
    const distance = Math.abs(axisEditionOrder - axisCurrentEditionOrder);
    return distance;
  }
}

export type ProductPage = typeof ProductPage;

function buildAxisEditionsOrder(
  axis: ProductPageFragment$data["axis1"] | undefined,
): Map<string, number> {
  return axis?.editions
    ? axis.editions.reduce((acc, edition, index) => {
        acc.set(edition?.id ?? "", index);
        return acc;
      }, new Map<string, number>())
    : new Map<string, number>();
}
