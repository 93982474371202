import React from "react";
import { AppBase } from "./AppBase";
import { AppContextProvider } from "./AppContextProvider";
import { AppToolbar } from "./AppToolbar";

export class App extends AppBase {
  renderContent(): JSX.Element {
    const { route } = this.state;
    if (!route?.component) return <></>;

    return (
      <AppContextProvider>
        <AppToolbar />
        {React.createElement(route.component, route.props)}
      </AppContextProvider>
    );
  }
}
