/**
 * @generated SignedSource<<7c6747d080f92cc335e9b0eb12bfe905>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductPageQuery$variables = {
  navName?: string | null;
  breadcrumbHeadNavName?: string | null;
  breadcrumbHeadType?: string | null;
  search?: string | null;
  categorySlug?: string | null;
  productCursor?: string | null;
  numProducts?: number | null;
};
export type ProductPageQuery$data = {
  readonly product: {
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProductPageFragment">;
  } | null;
  readonly me: {
    readonly maInfo: {
      readonly isInProgram: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CatalogBreadcrumbsFragment" | "ProductListFragment">;
};
export type ProductPageQuery = {
  variables: ProductPageQuery$variables;
  response: ProductPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "breadcrumbHeadNavName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "breadcrumbHeadType"
},
v2 = {
  "defaultValue": "populyarnoe",
  "kind": "LocalArgument",
  "name": "categorySlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "navName"
},
v4 = {
  "defaultValue": 8,
  "kind": "LocalArgument",
  "name": "numProducts"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productCursor"
},
v6 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v7 = [
  {
    "kind": "Variable",
    "name": "navName",
    "variableName": "navName"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProgram",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maker",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v16 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "editionId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Edition",
    "kind": "LinkedField",
    "name": "editions",
    "plural": true,
    "selections": [
      (v10/*: any*/),
      (v8/*: any*/)
    ],
    "storageKey": null
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "navName",
  "storageKey": null
},
v18 = [
  (v10/*: any*/)
],
v19 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "productCursor"
  },
  {
    "kind": "Variable",
    "name": "categoryNavName",
    "variableName": "categorySlug"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numProducts"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductPageFragment"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CatalogBreadcrumbsFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProductListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductImage",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAxis",
            "kind": "LinkedField",
            "name": "axis1",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAxis",
            "kind": "LinkedField",
            "name": "axis2",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAxis",
            "kind": "LinkedField",
            "name": "axis3",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdition",
            "kind": "LinkedField",
            "name": "editions",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Edition",
                "kind": "LinkedField",
                "name": "axis1Value",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Edition",
                "kind": "LinkedField",
                "name": "axis2Value",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Edition",
                "kind": "LinkedField",
                "name": "axis3Value",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "navName",
            "variableName": "breadcrumbHeadNavName"
          },
          {
            "kind": "Variable",
            "name": "type",
            "variableName": "breadcrumbHeadType"
          }
        ],
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "categoryPath",
        "plural": true,
        "selections": [
          (v10/*: any*/),
          (v8/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v19/*: any*/),
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "products",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v8/*: any*/),
                  (v17/*: any*/),
                  (v13/*: any*/),
                  (v11/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdition",
                    "kind": "LinkedField",
                    "name": "editions",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "variantName",
                        "storageKey": null
                      },
                      (v17/*: any*/),
                      (v13/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v19/*: any*/),
        "filters": [
          "search",
          "categoryNavName"
        ],
        "handle": "connection",
        "key": "ProductListFragment_products",
        "kind": "LinkedHandle",
        "name": "products"
      }
    ]
  },
  "params": {
    "cacheID": "d2e9ca8728ccbbe034ddcf95be5bdd34",
    "id": null,
    "metadata": {},
    "name": "ProductPageQuery",
    "operationKind": "query",
    "text": "query ProductPageQuery(\n  $navName: String\n  $breadcrumbHeadNavName: String\n  $breadcrumbHeadType: String\n  $search: String = \"\"\n  $categorySlug: String = \"populyarnoe\"\n  $productCursor: String = null\n  $numProducts: Int = 8\n) {\n  product(navName: $navName) {\n    name\n    ...ProductPageFragment\n    id\n  }\n  ...CatalogBreadcrumbsFragment\n  me {\n    maInfo {\n      isInProgram\n      id\n    }\n    id\n  }\n  ...ProductListFragment\n}\n\nfragment CatalogBreadcrumbsFragment on Query {\n  categoryPath(navName: $breadcrumbHeadNavName, type: $breadcrumbHeadType) {\n    id\n    name\n    navName\n    level\n  }\n}\n\nfragment ProductCard_product on Product {\n  id\n  name\n  navName\n  maker\n  image\n  unit\n  unitPrice\n  price\n  editions {\n    id\n    variantName\n    navName\n    maker\n    image\n    unit\n    unitPrice\n    price\n  }\n}\n\nfragment ProductListFragment on Query {\n  products(after: $productCursor, first: $numProducts, search: $search, categoryNavName: $categorySlug) {\n    edges {\n      node {\n        id\n        ...ProductCard_product\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment ProductPageFragment on Product {\n  id\n  name\n  description\n  image\n  images {\n    image\n    id\n  }\n  price\n  maker\n  unit\n  unitPrice\n  axis1 {\n    name\n    editionId\n    editions {\n      id\n      name\n    }\n  }\n  axis2 {\n    name\n    editionId\n    editions {\n      id\n      name\n    }\n  }\n  axis3 {\n    name\n    editionId\n    editions {\n      id\n      name\n    }\n  }\n  editions {\n    id\n    navName\n    axis1Value {\n      id\n    }\n    axis2Value {\n      id\n    }\n    axis3Value {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bf8c8722245641ef1f5486919aec33d";

export default node;
