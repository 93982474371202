/**
 * @generated SignedSource<<8ac1b8ca25a61a4cc910902542c5b6f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthProviderResetPasswordMutation$variables = {
  username: string;
  password: string;
  code: string;
};
export type AuthProviderResetPasswordMutation$data = {
  readonly resetPassword: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"Auth_user">;
    } | null;
  } | null;
};
export type AuthProviderResetPasswordMutation = {
  variables: AuthProviderResetPasswordMutation$variables;
  response: AuthProviderResetPasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "code"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v3 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthProviderResetPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResetPasswordPayload",
        "kind": "LinkedField",
        "name": "resetPassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Auth_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuthProviderResetPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResetPasswordPayload",
        "kind": "LinkedField",
        "name": "resetPassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCnt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attractedPurchasesAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e805d5092c92ed7fec46de62f72aa0c",
    "id": null,
    "metadata": {},
    "name": "AuthProviderResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation AuthProviderResetPasswordMutation(\n  $username: String!\n  $password: String!\n  $code: String!\n) {\n  resetPassword(username: $username, password: $password, code: $code) {\n    user {\n      ...Auth_user\n      id\n    }\n  }\n}\n\nfragment Auth_user on User {\n  id\n  username\n  email\n  phone\n  name\n  referralCnt\n  attractedPurchasesAmount\n}\n"
  }
};
})();

(node as any).hash = "5d41ce5f8df2fc0d5682483412d0f3d1";

export default node;
